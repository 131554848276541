<template>
	<div class="app-container" v-loading.fullscreen.lock="fullscreenLoading">
		<el-header class="titles">收入明细</el-header>
		<el-card class="elCard">
			<el-form :inline="true" class="demo-input-suffix commonSuf" style="margin-bottom: 0;">
				<el-form-item label="订单编号:" :label-width="formLabelWidth">
					<el-input placeholder="请输入订单编号" v-model="orderNo" clearable @keyup.native="orderNo=orderNo.replace(/[^a-zA-Z0-9]/g,'');loadData('init')"></el-input>
				</el-form-item>
				<el-form-item label="快递单号:" :label-width="formLabelWidth">
					<el-input placeholder="请输入快递单号" v-model="expressNumber" clearable @input='loadData("init")'></el-input>
				</el-form-item>
				<el-form-item label="用户手机:" :label-width="formLabelWidth">
					<el-input placeholder="请输入用户手机" v-model="userPhone" clearable @input='loadData("init")'></el-input>
				</el-form-item>
				<el-form-item label="兑换状态:" :label-width="formLabelWidth">
					<el-select v-model="receiptStatus" placeholder="请选择" clearable filterable @input='loadData("init")'>
						<el-option v-for="item in receArr" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="订单状态:" :label-width="formLabelWidth">
					<el-select v-model="withdrawStatus" placeholder="请选择" clearable filterable @input='loadData("init")'>
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</el-card>
		<div style="margin: 15px 0; text-align: right;">
			<el-button type="primary" icon="el-icon-refresh" @click='exportBtn()' style="float: left;">订单导出</el-button>
			<!-- 总计:订单总金额:{{sumData.sumPrice == null ? 0 : sumData.sumPrice}}元,实际获得金额{{sumData.sumRetailerPrice == null ? 0 : sumData.sumRetailerPrice}}元 -->
		    <el-button type="primary" icon="el-icon-refresh" @click='loadData("init")'>列表刷新</el-button>
		</div>
		<el-table :data="tableData" border style="width: 100%;font-size: 12px;" tooltip-effect="dark"
		 v-loading="loading" @sort-change='sortChange' >
			 <el-table-column prop="createdTxStamp" label="订单创建时间" min-width="120" sortable>
			 </el-table-column>
			<el-table-column prop="id" label="订单编号" min-width="100">
			</el-table-column>
			<el-table-column prop="transactionId" label="微信单号" min-width="100">
			</el-table-column>
			<el-table-column prop="integral" label="订单积分" min-width="80">
			</el-table-column>
			<el-table-column prop="orderMoney" label="订单金额" min-width="80">
			</el-table-column>
			<el-table-column prop="shippingMoney" label="订单运费" min-width="80">
			</el-table-column>
			<el-table-column prop="orderStatusName" label="订单状态" min-width="80">
			</el-table-column>
			<el-table-column prop="expressNumber" label="快递单号" min-width="100">
			</el-table-column>
			<el-table-column prop="shippingStatusName" label="订单配送状态" min-width="100">
			</el-table-column>
			<el-table-column prop="receiverMobile" label="用户手机号" min-width="100">
			</el-table-column>
			<el-table-column prop="receiverName" label="收货人姓名" min-width="90">
			</el-table-column>
			<el-table-column prop="receiverAddress" label="收货人地址" min-width="150">
			</el-table-column>
			<el-table-column prop="receiptStatus" label="兑换状态" width="80">
				<template slot-scope="scope">
					<span v-if="scope.row.receiptStatus == 0">兑换中</span>
					<span v-else-if="scope.row.receiptStatus == 1">取消兑换</span>
					<span v-else-if="scope.row.receiptStatus == 2">兑换成功</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" min-width="120" align='center' class-name="fixed" fixed="right">
				<template slot-scope="scope">
					<el-button style="margin-bottom: 5px; margin-right: 10px;" @click="delivery(scope.row)" type="primary" size="mini" v-if="scope.row.shippingStatus == 0">发货</el-button>
					<el-button style="margin-bottom: 5px; margin-right: 10px; margin-left: 0;" @click="refund(scope.row)" type="danger" size="mini" v-if="scope.row.orderStatus == 2">退款</el-button>
					<el-button style="margin-left: 0;" @click="showDetail(scope.row)" type="success" size="mini">订单详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination style="margin-top: 10px;" @size-change="((val)=>{$comjs.handleSizeChange(val,loadData)})"
		 @current-change="((val)=>{$comjs.handleCurrentChange(val,loadData)})" :current-page="$comjs.initPage.page"
		 :page-sizes="[5,10,20, 30, 50,100]" :page-size="$comjs.initPage.pageSize" layout="total,sizes, prev, pager, next,jumper"
		 :total="$comjs.total">
		</el-pagination>
		<el-dialog title="发货" :visible.sync="dialogTableVisibleExp" width='50%'>
			<el-form :inline="true" :model="expForm" :rules="expRules" ref="expForm" size="small" label-width="100px">
				<el-form-item label="快递公司：" prop="company">
					<el-select v-model="expForm.company" filterable clearable placeholder="请选择快递公司" autocomplete="off" style="max-width: 200px;">
						<el-option v-for="item in companyArr" :key="item.expressCode" :label="item.expressName" :value="item.expressCode"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="单号：" prop="orderNo">
					<el-input v-model="expForm.orderNo" placeholder="请输入单号"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogTableVisibleExp = false">取 消</el-button>
				<el-button type="primary" @click="dialogConfirmExp('expForm')">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog title="订单详情" :visible.sync="dialogTableVisible" width='80%'>
			<el-descriptions class="margin-top" title="" :column="3" border>
			    <el-descriptions-item label="订单创建时间">{{dataDetail.createdTxStamp}}</el-descriptions-item>
			    <el-descriptions-item label="订单编号">{{dataDetail.id}}</el-descriptions-item>
			    <el-descriptions-item label="微信单号">{{dataDetail.transactionId}}</el-descriptions-item>
			    <el-descriptions-item label="商品名称">{{dataDetail.orderDetail ? dataDetail.orderDetail.name : ''}}</el-descriptions-item>
			    <el-descriptions-item label="购买数量">{{dataDetail.orderDetail ? dataDetail.orderDetail.quantity : ''}}</el-descriptions-item>
				<el-descriptions-item label="订单积分">{{dataDetail.integral}}</el-descriptions-item>
			    <el-descriptions-item label="订单金额">{{dataDetail.orderMoney}}</el-descriptions-item>
			    <el-descriptions-item label="订单运费">{{dataDetail.shippingMoney}}</el-descriptions-item>
			    <el-descriptions-item label="订单状态">{{dataDetail.orderStatusName}}</el-descriptions-item>
			    <el-descriptions-item label="快递单号">{{dataDetail.expressNumber}}</el-descriptions-item>
			    <el-descriptions-item label="订单配送状态">{{dataDetail.shippingStatusName}}</el-descriptions-item>
			    <el-descriptions-item label="用户手机号">{{dataDetail.receiverMobile}}</el-descriptions-item>
			    <el-descriptions-item label="收货人姓名">{{dataDetail.receiverName}}</el-descriptions-item>
			    <el-descriptions-item label="收货人地址">{{dataDetail.receiverAddress}}</el-descriptions-item>
			    <el-descriptions-item label="兑换状态">{{dataDetail.receiptStatus == 0 ? '兑换中' : dataDetail.receiptStatus == 1 ? '取消兑换' : '兑换成功'}}</el-descriptions-item>
				<el-descriptions-item label="商品图片">
					<template>
						<el-image 
						    style="width: 100px;"
						    :src="dataDetail.orderDetail ? dataDetail.orderDetail.goodsPic : ''" 
						    :preview-src-list="srcList">
						</el-image>
						<!-- <img :src="dataDetail.orderDetail ? dataDetail.orderDetail.goodsPic : ''" style="width: 100px;" > -->
					</template>
				</el-descriptions-item>
			</el-descriptions>
		</el-dialog>
    </div>
</template>

<script>
	export default {
		data() {
			return {
				srcList: [],//图片预览数组
				expForm: {
					company: '',
					orderNo: ''
				},//发货内容
				expRules: {
					company: [{ required: true, message: '请选择快递公司', trigger: 'blur' }],
					orderNo: [{ required: true, message: '请输入快递单号', trigger: 'blur' }]
				},
				companyArr: [],//快递公司数组
				dialogTableVisibleExp: false,
				formLabelWidth: '120px',
				options:[{ value: 0, label: '待支付' },
					{ value: 1, label: '用户支付中' },
					{ value: 2, label: '支付成功' },
					{ value: 3, label: '支付失败' },
					{ value: 4, label: '转入退款' },
					{ value: 5, label: '已关闭' },
					{ value: 6, label: '已撤销' }
				],//订单状态
				receArr:[{ value: 0, label: '兑换中' },
					{ value: 1, label: '取消兑换' },
					{ value: 2, label: '兑换成功' },
				],//兑换状态
				loading:false,
				fullscreenLoading: false,
				orderNo:'',
				userPhone:'',
				expressNumber: '',
				receiptStatus: '',
				withdrawStatus:'',
				tableData:[],
				sumData:'',
				handleSizeChange2(val) {
					this.pageSize2 = val;
					this.loadDetailPage();
					// this.loadData();
				},
				handleCurrentChange2(val) {
					this.page2 = val;
					this.loadDetailPage()
					// this.loadData();
				},
				dialogTableVisible:false,
				orderId: '',
				dataDetail: {},//订单详情
			}
		},
		created() {
			this.$comjs.handInitPage(10);
			this.loadData('init');
		},
		methods:{
			// 发货
			delivery(obj) {
				this.orderId = obj.id;
				this.fullscreenLoading = true;
				this.$comjs.ajax.getAjax('/fa/exam/order/express-company', {}, this, (res) => {
					this.companyArr = res.list;
					this.fullscreenLoading = false;
					this.dialogTableVisibleExp = true;
				})
			},
			// 发货确定
			dialogConfirmExp(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// this.$comjs.integrationImg(this.imgUrlList);
						let data = {
							orderId: this.orderId,
							expressCompany: this.expForm.company,
							expressNumber: this.expForm.orderNo
						}
						this.$confirm('是否确定发货', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$comjs.ajax.postJsonAjax("/fa/exam/order/deliver", data, this, res => {
								this.dialogTableVisibleExp = false;
								this.$refs[formName].resetFields();
								this.$message({
									message: '发货成功！',
									type: 'success',
									duration: 1000
								});
								// 重新加载
								setTimeout(() => {
									this.loadData();
								}, 1000)
							});
						}).catch(() => {

						});
					}
				});
			},
			// 退款
			refund(obj) {
				this.$confirm('是否确定退款', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$comjs.ajax.postJsonAjax("/fa/exam/order/refund", {orderId: obj.id}, this, res => {
						this.$message({
							message: '退款成功！',
							type: 'success',
							duration: 1000
						});
						// 重新加载
						setTimeout(() => {
							this.loadData();
						}, 1000)
					});
				}).catch(() => {

				});
			},
			// 订单详情
			showDetail(obj){
				// this.orderNo = obj.orderNo
				this.loadDetailPage(obj.id);
			},
			loadDetailPage(id){
				this.fullscreenLoading = true;
				var data = {
					orderId:id
				}
				this.$comjs.ajax.getAjax('/fa/exam/order/detail', data, this, (res) => {
					this.dataDetail = res.data;
					this.srcList = [this.dataDetail.orderDetail.goodsPic];
					this.fullscreenLoading = false;
					this.dialogTableVisible = true;
				})
			},
			async loadData(init) {
				if (init) {
					this.$comjs.initPage.page = 1
				}
				this.loading = true;
				var data = Object.assign({}, this.$comjs.initPage);
				data.orderId = this.orderNo;
				data.receiverMobile = this.userPhone;
				data.expressNumber = this.expressNumber;
				data.receiptStatus = this.receiptStatus;
				data.orderStatus = this.withdrawStatus;
				await this.$comjs.ajax.getAjax('/fa/exam/order/query', data,this,(res) => {
					// this.sumData = res.data.sumData
					this.$comjs.total = res.total;
					this.tableData = res.list;
					this.loading = false;
					// this.$comjs.total = res.data.total;
					// this.loading = false;
					// console.log(res)
				})
			},
			// 排序函数
			sortChange(obj) {
				console.log(obj)
				this.$comjs.initPage.sidx = obj.prop;
				if (obj.order) {
					if (obj.order == 'ascending') {
						this.$comjs.initPage.sord = 'asc'
					} else {
						this.$comjs.initPage.sord = 'desc'
					}
				} else {
					this.sord = ''
				}
				this.loadData()
				// if(obj.)
			},
			downloadUtil(data, fileName) {
			  const a = document.createElement('a')
			  const href = window.URL.createObjectURL(new Blob([data])) // 创建下载的链接
			  a.href = href
			  // 文件名
			  a.download = decodeURIComponent(fileName);
			  document.body.appendChild(a)
			  a.click() // 点击下载
			  document.body.removeChild(a) // 下载完成移除元素
			  window.URL.revokeObjectURL(href) // 释放掉blob对象
			},
			// 订单导出
			async exportBtn() {
				this.fullscreenLoading = true;
				var data = Object.assign({}, this.$comjs.initPage);
				data.orderId = this.orderNo;
				data.receiverMobile = this.userPhone;
				data.expressNumber = this.expressNumber;
				data.receiptStatus = this.receiptStatus;
				data.orderStatus = this.withdrawStatus;
				this.$comjs.axios.get(this.$comjs.pre+'/fa/exam/order/export', {
				  params: data,
          responseType: "blob"
        }).then(res => {
          this.fullscreenLoading = false;
          const fileName = res.headers["content-disposition"].split(";")[1].split("=")[1]
          this.downloadUtil(res.data, fileName);
        })
			}
		}
	}
</script>

<style scoped="scoped">
	.app-container {
		overflow: auto;
		padding: 20px 20px 0;
	}
	.titles {
		border: 1px solid #E4E7ED;
		border-radius: 4px;
		line-height: 60px;
	}
	.elCard {
		margin-top: 20px;
	}
</style>
